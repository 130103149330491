<template>
  <div
    v-if="carregandoPagina"
    class="col-md-12"
    style="text-align: center; padding-top: 255px"
  >
    <pm-ProgressSpinner />
  </div>
  <section v-else>
    <!--<pm-Breadcrumb :home="home" :model="items_bread" />-->

    <div class="row">
      <div class="col-md-10">
        <h2 class="titulo-links-escolha">Alunos</h2>
      </div>
      <div class="col-md-2 tbl-cadastro" v-if="situacaoAno">
        <pm-Button
          type="button"
          v-if="acesso.cadastrar"
          @click="registar()"
          label="CADASTRAR ALUNO"
          class="p-button-success"
          icon="pi pi-external-link"
          title="CADASTRAR NOVO ALUNO"
        ></pm-Button>
      </div>
    </div>

    <div class="pm-card" v-if="acesso.visualizar">
      <pm-DataTable
        :value="item"
        dataKey="id"
        :rowHover="true"
        filterDisplay="menu"
        v-model:filters="filters"
        :globalFilterFields="['nome']"
      >
        <!--responsiveLayout="scroll"-->
        <template #header>
          <div class="grid" style="font-size: 12px">
            <!-- <div class="col-8 grid"> -->
            <div class="mr-2">
              <h5 class="mt-2" style="font-size: 14px;">Pesquisar:</h5>
            </div>
            <div class="grid sm:col-4 md:col-4 lg:col-5 xl:col-4 m-0 p-0 mb-2 mr-2">
              <!-- <div class="p-0 col-12"> -->
              <pm-InputText
                style="height: 34px !important; width: 100% !important;"
                v-model="pesquisa"
                @keyup.enter="pesquisarNomeCompletoEscola"
                placeholder="Nome Completo"
              />
              <!-- </div> -->
            </div>

            <div class="mr-2 p-0 sm:col-6 md:col-5 lg:col-3 xl:col-3 mb-2">
              <select
                class="p-inputtext p-component"
                v-model="escola"
                style="appearance: revert !important; width: 100%;"
                @change="pesquisarNomeCompletoEscola"
              >
                <option value="0" > Selecione uma Escola </option>
                <option v-for="e in usuario_logado.escolas" :value="e.id" :key="e.id" > {{ e.nome }} </option>
              </select>
            </div>

            <div class="grid sm:col-2 md:col-2 lg:col-2 xl:col-2 m-0 p-0 mb-2 mr-2">
              <pm-InputText
                style="height: 34px !important; width: 100% !important;"
                type="text"
                v-model="cpfAluno"
                @keyup.enter="pesquisarNomeCompletoEscola"
                placeholder="CPF do aluno"
              />
            </div>

            <div class="mr-2 p-0 sm:col-12 md:col-12 lg:col-12 xl:col-12"></div>

            <div class="mr-2 p-0 sm:col-1 md:col-1 lg:col-1 xl:col-1 mb-2">
              <label style="margin-bottom: 1px !important;margin-top: 1px !important;">Sexo:</label>
              <select
                class="p-inputtext p-component"
                v-model="sexo"
                style="appearance: revert !important; width: 100%;"
                @change="pesquisarNomeCompletoEscola"
              >
                <option value="0" > Selecione o sexo </option>
                <option v-for="sexo in sexos" :value="sexo.name"  :key="sexo.id"> {{ sexo.name }} </option>
              </select>
            </div>

            <div class="mr-2 p-0 sm:col-2 md:col-2 lg:col-2 xl:col-2 mb-2">
              <label style="margin-bottom: 1px !important;margin-top: 1px !important;">Cor/Raça:</label>
              <select
                class="p-inputtext p-component"
                v-model="cor"
                style="appearance: revert !important; width: 100%;"
                @change="pesquisarNomeCompletoEscola"
              >
                <option value="0" > Selecione uma cor/raça </option>
                <option v-for="cor in cores" :value="cor.name" :key="cor"> {{ cor.name }} </option>
              </select>
            </div>
            <div class="grid sm:col-2 md:col-2 lg:col-2 xl:col-2 m-0 p-0 mb-2 mr-2">
              <label style="margin-bottom: 1px !important;margin-top: 1px !important;">Nascimento:</label>
              <pm-InputText
                style="height: 34px !important; width: 100% !important;"
                type="number"
                v-model="anoNascimento"
                @keyup.enter="pesquisarNomeCompletoEscola"
                placeholder="Ano de nascimento do aluno"
              />
            </div>


            <!-- <div class="flex"> -->
            <div class="mr-2" v-if="usuario_logado && usuario_logado.escolas && usuario_logado.escolas.length>0"
              style="margin-top: 15px;">
              <pm-Button
                style="height: 34px !important;"
                type="button"
                @click="limparFiltro"
                label="Limpar filtro"
                class="p-button-secondary p-button-sm"
                title="Limpar filtro"
              />
            </div>

            <div class="" v-if="usuario_logado && usuario_logado.escolas && usuario_logado.escolas.length>0"
              style="margin-top: 15px;">
              <pm-Button
                style="height: 34px !important;"
                type="button"
                @click="pesquisarNomeCompletoEscola"
                label="Pesquisar"
                class="p-button-info p-button-sm"
                title="Pesquisar"
              />
            </div>
            <!-- </div> -->


            <!--
            <div class="field col-12 md:col-4 lg:col-3" style="margin: 0px">
              <span class="p-input-icon-left">
                <pm-InputText
                  v-model="pesquisa"
                  @keyup.enter="pesquisar"
                  placeholder="Nome"
                />
              </span>
            </div>
            <div class="field col-12 md:col-4 lg:col-3" style="margin: 0px">
              <span class="p-input-icon-left">
                <pm-InputText
                  v-model="pesquisa_sobrenome"
                  @keyup.enter="pesquisarSobrenome"
                  placeholder="Sobrenome"
                />
              </span>
            </div>
            -->

          </div>

          <div style="font-size: 13px;">
            Foram identificados {{ this.contagem }} alunos.
          </div>

        </template>
        <!--<pm-Column field="foto" header="FOTO" title="FOTO DE PERFIL" class="colunn-tbl">
          <template #body="{ data }">
            <div class="tb-center" v-if="data.foto">
              <img
                v-if="data.foto"
                class="img-perfil-dtb-pq"
                :src="base_url + '/storage/' + data.foto"
                @click="fotoOpen(data.foto, 'A')"
              />
            </div>
            <div class="tb-center" v-if="data.foto == null">
              <pm-Avatar
                icon="pi pi-user"
                class="mr-2"
                style="background-color: #e1e1e1; color: #ffffff"
                shape="circle"
              />
            </div>
          </template>
        </pm-Column>-->
        <pm-Column field="foto" header="" title="Aviso" class="colunn-tbl">
          <template #body="{ data }">
            <span
              class="badge bg-danger table_style"
              v-if="data.cpf == null"
              title="Aluno possui pendências no cadastro!"
              style="padding: 6px;"
            >
              <i class="fa fa-exclamation-triangle" style="font-size:12px;"></i>
            </span>
          </template>
        </pm-Column>
        <pm-Column
          field="nome"
          sortable
          header="NOME"
          class="colunn-tbl col-nome-alu"
        >
          <template #body="{ data }">
            {{ data.nome + " " + data.sobrenome + " " }}
            &nbsp;
            <span
              class="badge bg-danger table_style"
              v-if="data.deficiencia == 1"
              :title="data.qual_deficiencia"
            >PCD</span
            >
          </template>
        </pm-Column>
        <pm-Column
          field="nome_mae"
          sortable
          header="MÃE"
          class="colunn-tbl col-mae"
        >
          <!--<template #header="{}">
           <span style="order: 3;border: 1px solid #656162;margin-left: 25px;padding: 5px;border-radius: 40px;padding-bottom: 2px;">
            <i class="text-sm text-color-secondary pi pi-arrows-h" style="color: #676768;"></i>
          </span>
          </template>-->
        </pm-Column>
        <pm-Column
          field="cpf"
          sortable
          header="CPF"
          class="colunn-tbl"
          style="width: 140px;"
        ></pm-Column>

        <pm-Column
          field=""
          class="table_style colunn-tbl"
          header="Nascimento"
        >
          <template #body="{ data }">
            <div>
              {{ formatData(data.nascimento) }}
            </div>

          </template>
        </pm-Column>

        <pm-Column
          field="sexo"
          sortable
          class="table_style colunn-tbl"
          header="Sexo"
        >
          <template #body="{ data }">
            <div :style="textCor('sexo',data.sexo)">
              {{ data.sexo }}
            </div>
          </template>
        </pm-Column>

        <pm-Column
          field="cor_raca"
          sortable
          class="table_style colunn-tbl"
          header="Cor/Raça"
        >
          <template #body="{ data }">
            <div>
              {{ data.cor_raca }}
            </div>
          </template>
        </pm-Column>

        <pm-Column field="" header="SITUAÇÃO" class="colunn-tbl" style="text-align: center;">
          <template
            #body="{ data }"
            v-if="this.funcao == 1 || this.funcao == 2 || this.funcao == 7"
          >
            <va-badge
              v-if="data.situacao == 0"
              class="table_style"
              :text="'INATIVO'"
              :color="'#f50000'"
              title="Click para Ativar o Aluno."
              @click="mudarSituacao(data.id)"
              style="cursor: pointer"
            />
            <va-badge
              v-if="data.situacao == 1"
              class="table_style"
              :text="'ATIVO'"
              :color="'#09b163'"
              title="Click para Desativar o Aluno."
              @click="mudarSituacao(data.id)"
              style="cursor: pointer"
            />
          </template>
          <template #body="{ data }" v-else>
            <va-badge
              v-if="data.situacao == 0"
              class="table_style"
              :text="'INATIVO'"
              :color="'#f50000'"
            />
            <va-badge
              v-if="data.situacao == 1"
              class="table_style"
              :text="'ATIVO'"
              :color="'#09b163'"
            />
          </template>
        </pm-Column>
        <pm-Column
          field=""
          sortable
          class="table_style colunn-tbl"
          header="TURMA"
        >
          <template #body="{ data }">
            <div :class="{ 'turma-dados-disabled': !situacaoAno }">
              <a
                @click="irPgAluno(data.turma.turma)"
                v-if="data.turma != 'sem turma' && data.turma.turma != false"
                style="cursor: pointer"
              >
                <va-badge
                  v-if="data.turma.turma.nome"
                  class="table_style"
                  :text="data.turma.turma.nome"
                  :color="'#6c757d'"
                />
              </a>
              <div v-else>
                <va-badge :text="'SEM TURMA'" :color="'#fba92d'" />
              </div>
            </div>

          </template>
        </pm-Column>

        <pm-Column
          field="id"
          headerStyle=" text-align: center"
          bodyStyle="text-align: center; overflow: visible;vertical-align:middle !important"
          class="colunn-tbl tbl-aluno-btn"
        >
          <template #body="{ data }">
            <pm-Button
              :disabled="situacaoAno == 0"
              type="button"
              @click="alunoEscolaTransf = data;showModalTransfEscola=true;"
              v-if="data.rematricula_dados.length != 0 && data.qdt_vezes_turma == 0 && data.transferenciaRematriculado == null"

              label=""
              title="Transferir Aluno Rematriculado para outra Escola"
              class="p-button-sm btn-color table_style p-button-help"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            >
              <svg width="21" height="21" viewBox="0 0 181 161" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M80 11C80 4.92487 84.9249 0 91 0H125C127.761 0 130 2.23858 130 5V25C130 27.7614 127.761 30 125 30H100.985L127.075 49H159C171.15 49 181 58.8497 181 71V97.0046C176.4 88.8208 169.421 82.1536 161 77.9409V74C161 71.2386 158.761 69 156 69H145C142.581 69 140.563 70.7178 140.1 73.0001L140 73C117.474 73 98.6497 88.8466 94.0661 110H90C81.7157 110 75 116.716 75 125V161H22C9.84974 161 0 151.15 0 139V71C0 58.8497 9.84974 49 22 49H52.9248L80 29.2825V11ZM36 69C38.7614 69 41 71.2386 41 74V96C41 98.7614 38.7614 101 36 101H25C22.2386 101 20 98.7614 20 96L20 74C20 71.2386 22.2386 69 25 69H36ZM41 115C41 112.239 38.7614 110 36 110H25C22.2386 110 20 112.239 20 115L20 135C20 137.761 22.2386 140 25 140H36C38.7614 140 41 137.761 41 135V115ZM90 91C98.8366 91 106 83.8366 106 75C106 66.1634 98.8366 59 90 59C81.1634 59 74 66.1634 74 75C74 83.8366 81.1634 91 90 91ZM181 120C181 142.644 162.644 161 140 161C117.356 161 99 142.644 99 120C99 97.3563 117.356 79 140 79C162.644 79 181 97.3563 181 120ZM141.829 102.829C143.391 101.266 145.924 101.266 147.485 102.829L159.507 114.849C159.562 114.905 159.616 114.961 159.668 115.02C160.485 115.752 161 116.816 161 118C161 118.762 160.787 119.475 160.417 120.081C160.223 120.593 159.918 121.073 159.506 121.485L147.485 133.506C145.923 135.068 143.391 135.068 141.828 133.506C140.267 131.944 140.267 129.412 141.828 127.849L147.678 122H124C121.791 122 120 120.209 120 118C120 115.791 121.791 114 124 114H147.344L141.829 108.485C140.267 106.923 140.267 104.391 141.829 102.829Z" fill="#fff"/>
              </svg>
            </pm-Button>

            <!-- <pm-Button
              :disabled="situacaoAno == 0"
              type="button"
              @click="carteirinha(data)"
              icon="pi pi-camera"
              label=""
              title="Carteirinha escolar"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            >
            </pm-Button> -->
            <pm-Button
              :disabled="situacaoAno == 0"
              type="button"
              @click="fotos(data)"
              icon="pi pi-camera"
              label=""
              title="Foto de Perfil"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            >
            </pm-Button>

            <pm-Button
              :disabled="situacaoAno == 0"
              title="Adicionar arquivos importantes sobre o aluno."
              type="button"
              v-if="acesso.cadastrar"
              @click="addArquivo(data.id)"
              label=""
              icon="pi pi-upload"
              class="p-button-success p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            ></pm-Button>

            <pm-Button
              :disabled="situacaoAno == 0"
              title="Verificar o histórico do aluno."
              type="button"
              v-if="acesso.visualizar"
              @click="historicoAluno(data.id)"
              label=""
              icon="pi pi-calendar"
              class="p-button-warning p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            ></pm-Button>
            <pm-Button
              :disabled="situacaoAno == 0"
              title="Transferir aluno"
              type="button"
              v-if="data.turma != 'sem turma' && data.turma.turma != false"
              @click="transferenciaReceberDados(data)"
              label=""
              icon="pi  pi-sign-out"
              class="p-button-secondary p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            ></pm-Button>
            <pm-Button
              disabled
              title="Transferir aluno"
              type="button"
              v-else
              label=""
              icon="pi pi-sign-out"
              class="p-button-secondary p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            ></pm-Button>

            <pm-Button
              :disabled="situacaoAno == 0"
              type="button"
              @click="loginmodal(data)"
              icon="pi pi-users"
              label=""
              title="Atualizar Login"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
              v-if="this.funcao == 1 || this.funcao == 2 || this.funcao == 7"
            >
            </pm-Button>

            <pm-Button
              :disabled="situacaoAno == 0"
              type="button"
              @click="editarAluno(data.id)"
              icon="pi pi-user-edit"
              label=""
              title="Editar dados dos alunos(CPF, nome, email, etc.)"
              v-if="situacaoAno && acesso.editar"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            ></pm-Button>

            <pm-Button
              :disabled="situacaoAno == 0"
              title="Apagar as informações cadastradas do aluno."
              type="button"
              v-if="acesso.deletar"
              @click="deletarAluno(data.id)"
              label=""
              icon="pi pi-trash"
              class="p-button-danger p-button-sm btn-color table_style"
              style="margin-right: 5px;margin-bottom: 5px;width: 1.8rem !important;padding: 0.2rem 0.5rem !important;"
            >
            </pm-Button>
          </template>
        </pm-Column>
      </pm-DataTable>
      <div class="p-paginator p-component p-paginator-bottom">
        <div class="p-paginator-pages">
          <div v-for="n in links" :key="n">
            <button
              class="p-paginator-next p-paginator-element p-link"
              @click="paginas(n)"
              v-if="!n.active"
              style="border: 0px solid #ebebeb; border-radius: 0"
            >
              <p
                class="p-paginator-icon"
                v-html="n.label"
                style="margin-bottom: 0px !important"
              ></p>
            </button>
            <button
              class="p-paginator-page p-paginator-element p-link p-highlight"
              @click="paginas(n)"
              v-if="n.active"
              style="border-radius: 0"
            >
              {{ n.label }}
            </button>
          </div>
        </div>
        <selectPorPag :key="perPage" :qdt="perPage" @changePagina="changePagina"></selectPorPag>
      </div>
    </div>

    <va-modal v-model="showModal" hide-default-actions overlay-opacity="0.2">
      <slot>
        <div>
          <img :src="foto_grande" style="width: 400px" />
        </div>
      </slot>
    </va-modal>

    <va-modal
      v-model="showModalLogin"
      hide-default-actions
      overlay-opacity="0.2"
    >
      <slot>
        <div class="p-fluid formgrid grid">
          <div class="field col-12 md:col-12">
            <br />
            <h5>Acesso ao sistema:</h5>
          </div>
          <div
            class="field col-12 md:col-12"
            v-if="temlogin == 0"
            style="
              background-color: #00d7f9;
              padding: 15px;
              color: #fff;
              border-radius: 5px;
              margin-bottom: 20px;
            "
          >
            <span class="label label-info"> Aluno não possui Login!</span>
          </div>
          <div class="field col-12 md:col-12">
            <label for="firstname">Email:</label>
            <pm-InputText
              id="email"
              :style="greenColor"
              v-model="info.email"
              v-on:blur="verificarEmail"
            />
            <small v-show="emailInvalidoJaExiste" class="p-error"
            >O Email digitado já está em uso!</small
            >
          </div>
          <div class="field col-12 md:col-12">
            <label for="senha">Senha:</label>
            <pm-Password
              v-model="info.senha"
              weakLabel="Fraca"
              mediumLabel="Média"
              strongLabel="Forte"
              toggleMask
              :class="{ 'p-invalid': validationErrors.senha && submitted }"
            ></pm-Password>
            <small v-show="validationErrors.senha" class="p-error"
            >As Senhas não coincidem!</small
            >
          </div>
          <div class="field col-12 md:col-12">
            <label for="senha">Confirmar senha:</label>
            <pm-Password
              v-model="info.confirmarSenha"
              weakLabel="Fraca"
              mediumLabel="Média"
              strongLabel="Forte"
              toggleMask
              :class="{
                'p-invalid': validationErrors.confirmarSenha && submitted,
              }"
            ></pm-Password>
            <small v-show="validationErrors.confirmarSenha" class="p-error"
            >Senhas não coincidem!</small
            >
          </div>
          <div class="field col-4 md:col-4">
            <pm-Button
              type="button"
              @click="AtualizarLogin()"
              icon="pi pi-user-edit"
              label="Atualizar Login"
              title="Atualizar Login"
              class="p-button-sm btn-color table_style"
              style="margin-right: 5px"
            >
            </pm-Button>
          </div>
          <div class="field col-4 md:col-4" v-if="this.temlogin == 1">
            <pm-Button
              title="Apagar o login do aluno."
              type="button"
              @click="deletarLogin()"
              label="Deletar Login"
              icon="pi pi-trash"
              class="p-button-danger p-button-sm btn-color table_style"
              style="margin-left: 5px"
            >
            </pm-Button>
          </div>
        </div>
      </slot>
    </va-modal>

    <!--Modals-->
    <va-modal
      v-model="showMediumModal"
      :title="$t(tituloModal)"
      :message="$t(textoModal)"
      position="top"
      :okText="$t('modal.confirm')"
      :cancelText="$t('modal.cancel')"
    />

    <va-modal v-model="abrirModalRemover" hide-default-actions>
      <template #header>
        <h2 style="color: #2196f3">Remover Aluno na Turma</h2>
      </template>
      <slot>
        <p>
          <b
          >Deseja remover o aluno(a)
            <span
              class="badge badge-secondary2"
              style="font-size: 15px !important"
            >{{ alunoSelecionadoDadosTransferencia.nome }}</span
            >
            ? Selecione o motivo!</b
          >
        </p>
        <div class="row" style="margin-bottom: 20px">
          <div class="col-md-12">
            <fieldset>
              <va-radio
                option="option0"
                v-model="radioSelectedOption"
                label="Transferência interna do aluno"
              />
              <br />
              <va-radio
                option="option1"
                v-model="radioSelectedOption"
                label="Saiu da escola (Transferido)"
              />
              <br />
              <va-radio
                option="option2"
                v-model="radioSelectedOption"
                label="Saiu da escola (Evadido)"
              />
              <br />
              <va-radio
                style="margin-top: 10px"
                option="option3"
                v-model="radioSelectedOption"
                label="Faleceu"
              />
              <br />
              <va-radio
                style="margin-top: 10px"
                option="option4"
                v-model="radioSelectedOption"
                label="Reclassificado"
              />
              <br />
            </fieldset>
          </div>
        </div>
        <pm-Button
          type="button"
          @click="transferenciaPrimeiraEtapa"
          label="Realizar envio de dados para transferência"
          title="Realizar ação"
          class="p-button btn-color"
        >
        </pm-Button>
      </slot>
    </va-modal>

    <pm-Dialog  header="Área do aluno rematriculado" v-model:visible="showModalTransfEscola" :style="{width: '30vw'}"  :modal="true"  after-hide="clearUser">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-12"><h5>Solicitar transferência para outra Escola</h5></div>

        <div class="field col-12 md:col-12" style="margin-bottom: 0px;">
          <label style="width: 100%;margin-bottom: 1px !important;margin-top: 1px !important;">Nome: <strong>{{ alunoEscolaTransf.nome+" "+alunoEscolaTransf.sobrenome }}</strong></label>
          <label style="width: 100%;margin-bottom: 1px !important;margin-top: 1px !important;">Nascimento: {{ formatData(alunoEscolaTransf.nascimento) }}</label>
          <label style="width: 100%;margin-bottom: 1px !important;margin-top: 1px !important;">Nome da Mãe: {{ alunoEscolaTransf.nome_mae }}</label>
          <label style="width: 100%;margin-bottom: 1px !important;margin-top: 1px !important;" v-if="alunoEscolaTransf.rematricula_dados[0]">
            Escola Atual: <b>{{ alunoEscolaTransf.rematricula_dados[0].escola.nome }}</b>
          </label>
          <hr>
        </div>
        <div class="field col-12 md:col-6">
          <label>Selecione a nova Escola:</label>
          <select
            class="p-inputtext p-component"
            v-model="escolaNova"
            style="appearance: revert !important; width: 100% !important"
          >
            <option value="" disabled selected>
              -- Escolha uma Escola --
            </option>
            <option v-for="n in filterEscolasTransfDiferent" :key="n" :value="n.id">
              {{ n.nome }}
            </option>
          </select>
        </div>
      </div>

      <template #footer>
        <pm-Button v-if="loadingButton" label="aguarde" disabled autofocus />
        <pm-Button v-else label="Solicitar" @click="SolicitacaoTransferenciaEscola" autofocus />
      </template>
    </pm-Dialog>

    <!-- Carteirinha -->
    <!-- <pm-Dialog  :maximizable="true" header="Carteirinha" v-model:visible="modalCartao" :style="{width: '80vw'}"  :modal="true"  after-hide="clearUser">
      <div style="background-color: rgb(228, 228, 228) !important;" class=" grid justify-content-center">
        <cartaoMatricula :aluno="cartaoAluno"></cartaoMatricula>
      </div>
    </pm-Dialog> -->
  </section>
</template>

<script>
import { defineComponent } from "vue";
import { Aluno } from "@/class/alunos.js";
import { Escola } from "@/class/escolas.js";
import { Verificar } from "@/class/verificar.js";
import axios from "axios";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import Util from "@/utils/rota";
import { Transferecia } from "@/class/transferencia.js";

import cartaoMatricula from "@/components/cartao_matricula/CartaoMatricula.vue";
import selectPorPag from "@/components/selectPorPag.vue";
import pagina from "@/class/pagina";

export default defineComponent({
  components: {
    selectPorPag,
    cartaoMatricula,
  },
  props: {
    pg: {
      default: 1,
    },
  },
  data() {
    const columns = [
      { key: "codigo_INEP", label: "Codigo INEP" },
      { key: "nome", label: "Nome", sortable: true },
      { key: "nome_mae", label: "Mãe", sortable: true },
      { key: "cpf" },
      { key: "rg" },
      { key: "deficiencia" },
      { key: "id", label: "Ação" },
    ];

    return {
      contagem:0,
      funcao: 0,
      showModalLogin: false,
      showModal: false,
      modalCartao: false,
      loadingButton:false,
      showModalTransfEscola:false,
      alunoEscolaTransf:[],
      base_url: axios.defaults.baseURL.replace("/api/v1", ""),
      home: { icon: "pi pi-home", to: "/admin/painel-adm" },
      items_bread: [{ label: "Alunos" }],
      filters: {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        nome: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      },
      situacaoAno: 1,
      filter: "",
      showMediumModal: false,
      tituloModal: "",
      textoModal: "",

      item: [],
      links: [],
      columns,
      perPage: 50,
      currentPage: 1,
      pagina_atual: 1,
      linha: 12,
      adc_turma: false,
      addturma: {
        ano: 0,
      },
      pesquisa: null,
      pesquisa_sobrenome: null,
      foto_grande: null,
      acesso: {
        visualizar: 1,
        cadastrar: 1,
        editar: 1,
        deletar: 1,
      },

      nome_aluno: "",
      validationErrors: {},
      emailInvalidoJaExiste: "",
      info: {
        id: "",
        email: "",
        senha: "",
        confirmarSenha: "",
        emailDele: "",
      },
      id: null,
      temlogin: 1,
      abrirModalRemover: false,
      radioSelectedOption: "option0",
      carregandoPagina: false,
      alunoSelecionadoDadosTransferencia: {
        nome: "",
        turma_inicial: "",
        aluno_id: "",
      },
      usuario_logado: { escolas: [] },
      escola: 0,
      sexos: [{id: 1, name: 'MASCULINO'}, {id: 2, name: 'FEMININO'}, {id: 3, name: 'OUTROS'}],
      cores: [{id: 1, name: 'BRANCA'}, {id: 2, name: 'PARDA'}, {id: 3, name: 'PRETA'}, {id: 4, name: 'INDIGENA'}, {id: 5, name: 'NÃO DECLARADA'}], //['}'BRANCA', 'PARDA', 'PRETA', 'INDIGENA', 'NÃO DECLARADA'],
      cor: 0,
      sexo: 0,
      anoNascimento: null,
      escolaALL: [],
      escolaNova:"",
      itemPag:{},
      cartaoAluno:{},
      cpfAluno: undefined
    };
  },

  computed: {
    filterEscolasTransfDiferent() {
      if(this.alunoEscolaTransf.rematricula_dados.escola_nome){
        return this.escolaALL.filter(escola => escola.id !== this.alunoEscolaTransf.rematricula_dados.escola_id);
      }else{
        return this.escolaALL;
      }
    },
  },

  async beforeMount() {
    await this.logado();

    this.situacaoAno = parseInt(sessionStorage.getItem("anoSitucao"));
    this.listarTudo();

    if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
      const acesso = await Verificar.AcessoLocal(8);
      this.acesso.visualizar = acesso.visualizar;
      this.acesso.cadastrar = acesso.cadastrar;
      this.acesso.editar = acesso.editar;
      this.acesso.deletar = acesso.deletar;
    } else {
      const acesso = await Verificar.AcessoLocal(9);
      this.acesso.visualizar = acesso.visualizar;
      this.acesso.cadastrar = acesso.cadastrar;
      this.acesso.editar = acesso.editar;
      this.acesso.deletar = acesso.deletar;
    }
    await this.buscarEscolas();
  },
  methods: {
    carteirinha(aluno){
      this.cartaoAluno = aluno;
      console.log('al',this.cartaoAluno)
      this.modalCartao = !this.modalCartao;
    },
    async SolicitacaoTransferenciaEscola(){
      this.loadingButton = true;

      this.escolaNova === "" ? this.escolaNova = 0 : this.escolaNova;
      const anoSelecionado = parseInt(sessionStorage.getItem("anoSelecionado"));
      const res = await axios.post('/transferencias',{
        'transferencia_escola': 1,
        'ano': anoSelecionado,
        'aluno_id': this.alunoEscolaTransf.rematricula_dados[0].aluno_id,
        'escola_inicial': this.alunoEscolaTransf.rematricula_dados[0].escola_id,
        'escola_destino': this.escolaNova,
      }).then((resp) => {
        this.$vaToast.init({
            message: resp.data.message,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            color: "success",
            fullWidth: false,
          });
          this.escolaNova = "";
          this.showModalTransfEscola = false;
        }).catch((error)=>{
          this.$vaToast.init({
            message: 'Ocorreu um problema!',
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            color: "danger",
            fullWidth: false,
          });
        }).finally(()=>{
          this.loadingButton = false;
        });

    },
    async buscarEscolas(){
      const data = await Escola.obtemTodos();
      this.escolaALL = data.data;
    },
    textCor(local,value){
      let res = '';
      if(local == 'sexo'){
        if(value == 'M'){
          res = '#027abc'
        }
        else if(value == 'F'){
          res = '#d32f2f'
        }
        else if(value == 'O'){
          res = '#fbc02d'
        }
      }

      return "color:"+res;
    },
    formatData(data){
      const date = data.split("-");
      return date[2]+'/'+date[1]+'/'+date[0];
    },
    async changePagina(pg){
      pagina.routePg = "&pg="+pg
      pagina.pg = pg

      const routePg = pagina.routePg;
      const res = await axios.get(this.itemPag[1].url+routePg);
      this.item = [];
      this.item = res.data.data;
      this.links = res.data.links;
      this.itemPag = res.data.links;
      this.contagem = res.data.total;
      this.perPage = res.data.per_page;
    },
    limparFiltro(){
      this.pesquisa = null
      this.escola = 0
      this.anoNascimento = null
      this.cpfAluno = undefined
      this.cor = 0
      this.sexo =0
      this.listarTudo();
    },
    async logado() {
      const token = sessionStorage.getItem("token");
      const util = new Util();
      const data = await util.WhoIam(token);
      this.usuario_logado = data;
      this.funcao = data.funcao;
    },
    async loginmodal(data) {
      this.info.id = "";
      this.info.email = "";
      this.info.senha = "";
      this.info.confirmarSenha = "";
      this.id = null;
      this.nome_aluno = data.nome + " " + data.sobrenome;
      this.showModalLogin = true;
      this.id = data.id;
      this.info.id = data.id;
      const dt = await Aluno.obtemUm(this.id);
      if (dt.data[0].login) {
        this.info.email = dt.data[0].login.email;
        this.info.emailDele = dt.data[0].login.email;
      }

      if (dt.data[0].login.length == 0) {
        this.temlogin = 0;
      } else {
        this.temlogin = 1;
      }
    },
    transferenciaReceberDados(dados) {
      this.alunoSelecionadoDadosTransferencia.nome =
        dados.nome + " " + dados.sobrenome;
      this.alunoSelecionadoDadosTransferencia.aluno_id = dados.id;
      this.alunoSelecionadoDadosTransferencia.turma_inicial = dados.turma.turma.id;
      this.abrirModalRemover = true;
    },
    getMotivo() {
      let motivo = "";
      if (this.radioSelectedOption == "option0") {
        motivo = "Transferido Internamente";
      }
      if (this.radioSelectedOption == "option1") {
        motivo = "Transferido Externamente";
      } else if (this.radioSelectedOption == "option2") {
        motivo = "Evadido";
      } else if (this.radioSelectedOption == "option3") {
        motivo = "Falecido";
      } else if (this.radioSelectedOption == "option4") {
        motivo = "Reclassificado";
      } else if (this.radioSelectedOption == "option5") {
        motivo = "Entrou na turma errada";
      }
      return motivo;
    },

    transferenciaPrimeiraEtapa() {
      const dados = {
        aluno_id: this.alunoSelecionadoDadosTransferencia.aluno_id,
        turma_inicial: this.alunoSelecionadoDadosTransferencia.turma_inicial,
        tipo_evento: this.getMotivo(),
      };
      this.carregandoPagina = true;
      Transferecia.store(dados)
        .then((resp) => {
          this.$vaToast.init({
            message: resp.data.message,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            color: "success",
            fullWidth: false,
          });

          setTimeout(() => {
            if (
              window.confirm(
                "Você deseja finalizar a transferência do aluno agora?"
              )
            ) {
              if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
                this.$router.push({ name: "gestor/transferencia" });
              } else {
                this.$router.push({ name: "coordenador/transferencia" });
              }
            }
          }, 600);
        })
        .catch((err) => {
          this.$vaToast.init({
            message: err.response.data.error,
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3000,
            color: "danger",
            fullWidth: false,
          });
        })
        .finally(() => {
          this.carregandoPagina = false;
          this.abrirModalRemover = !this.abrirModalRemover;
          this.radioSelectedOption = "option0";
        });
    },
    async AtualizarLogin() {
      //const data = await Aluno.obtemUm(this.id);
      const data = await Aluno.alterarLogin(this.info);
      this.showModalLogin = false;
      this.$vaToast.init({
        message: "Login Atualizado com sucesso!",
        iconClass: "fa-star-o",
        position: "top-right",
        duration: 2500,
        fullWidth: false,
      });
      this.listarTudo();
    },
    async deletarLogin() {
      const data = await Aluno.deletarLogin(this.info);
      this.showModalLogin = false;
      this.$vaToast.init({
        message: "Login Deletado com sucesso!",
        iconClass: "fa-star-o",
        position: "top-right",
        duration: 2500,
        fullWidth: false,
      });
    },
    irPgAluno(turma) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "turma-add-aluno",
          params: {
            etapa_id: turma.segmento_id,
            periodo_id: turma.serie_id,
            turma_id: turma.id,
            escola_id: turma.escola_id,
            turno: turma.turno,
            ativa: turma.ativa,
          },
        });
      } else {
        this.$router.push({
          name: "turma-add-aluno-coordenacao",
          params: {
            etapa_id: turma.segmento_id,
            periodo_id: turma.serie_id,
            turma_id: turma.id,
            escola_id: turma.escola_id,
            turno: turma.turno,
            ativa: turma.ativa,
          },
        });
      }
    },

    async mudarSituacao(id) {
      const data = await Aluno.mudarSituacaoAluno(id);
      if (
        data.data.erro ==
        "Aluno está em uma Turma este ano você deve ir na Turma e Dizer qual é o motivo, de Desativa-lo!"
      ) {
        alert(
          "Aluno está em uma Turma este ano você deve ir na Turma e Dizer qual é o motivo, de Desativa-lo!"
        );
      } else {
        this.listarTudo();
      }
    },
    fotoOpen(foto, sexo) {
      //quando a foto vier Null eu mando os Sexos 'F' ou 'M'
      //caso nao tiver null eu mando um 'A'

      if (sexo == "A") {
        this.foto_grande = this.base_url + "/storage/" + foto;
        this.showModal = true;
      }
    },
    async pesquisarNomeCompletoEscola() {
      //console.log(this.sexo);
      if ( (this.pesquisa != null && this.pesquisa != "") || (this.escola!=0) || (this.cor!=0) || (this.sexo!=0) || (this.ano_nascimento!=0) ) {
        let nome = this.pesquisa;
        let sobrenome = '';

        if( ! (this.pesquisa != null && this.pesquisa != "")  ){
            nome = '0'
        }
        if(nome.indexOf(' ')!=-1){
            nome = this.pesquisa.split(' ')[0]
            sobrenome = this.pesquisa.split(' ').slice(1).join(' ')
        }
        if(sobrenome==''){
            sobrenome='0';
        }

        let nasc = null;
        if(this.anoNascimento == null){
          nasc = 0;
        }else{
          if(this.anoNascimento == ''){
            nasc = 0
          }else{
            nasc = this.anoNascimento
          }
        }

        const ano = sessionStorage.getItem("anoSelecionado");

        const data = await axios.get(
          "/alunos/v2/" +
            nome +
            "/sobrenome/" +
            sobrenome +
            "/0"+
            "/escola/" +
            this.escola +
            "/cor/" +
            this.cor +
            "/ano_nascimento/" +
            nasc +
            "/sexo/" +
            (this.sexo == 'FEMININO' ? 'F' : this.sexo == 'MASCULINO' ? 'M' : this.sexo == 'OUTROS' ? 'O' : 0) +
            "/ano/" + ano +'?cpf='+this.cpfAluno
        );

        this.item = data.data.data;
        this.links = [];
        this.links = data.data.links;
        this.itemPag = data.data.links;
        this.contagem = data.data.total;
        this.anoNascimento == null;
        this.perPage = data.data.per_page.toString();
        console.log(data.data)
        console.log(this.perPage.toString())
      } else {
        this.listarTudo();
      }
    },
    async pesquisarNomeCompleto() {

      if (this.pesquisa != null && this.pesquisa != "") {
        let nome = this.pesquisa;
        let sobrenome = '';

        if(this.pesquisa.indexOf(' ')!=-1){
            nome = this.pesquisa.split(' ')[0]
            sobrenome = this.pesquisa.split(' ').slice(1).join(' ')
        }
        const data = await axios.get(
          "/alunos/v2/" +
            nome +
            "/sobrenome/" +
            sobrenome +
            "/0"
        );
        this.item = data.data;
        this.links = [];
        this.contagem = data.data.total;
        this.perPage = data.data.per_page;
      } else {
        this.listarTudo();
      }
    },
    async pesquisar() {
      if (this.pesquisa != null && this.pesquisa != "") {
        const data = await Aluno.pesqAlunoCompleto(this.pesquisa);
        this.item = data.data;
        this.links = [];
        this.contagem = data.data.total;
      } else {
        this.listarTudo();
      }
    },
    async pesquisarSobrenome() {
      if (this.pesquisa != null && this.pesquisa != "") {
        const data = await axios.get(
          "/alunos/v2/" +
            this.pesquisa +
            "/sobrenome/" +
            this.pesquisa_sobrenome +
            "/0"
        );
        this.item = data.data;
        this.links = [];
        this.contagem = data.data.total;
        this.perPage = data.data.per_page;
      } else {
        this.listarTudo();
      }
    },
    async paginas(n) {
      this.itemPag = n;
      this.carregandoPagina = true;
      if (n.url != null) {
        if (!n.active) {
          const numero = n.url.split("?page=");
          this.pagina_atual = numero[1];

          if (numero == 1) {
            const res = await Aluno.obtemTodos(this.currentPage);
            this.item = [];
            this.item = res.data.data;
            this.links = res.data.links;
            this.itemPag = res.data.links;
            this.contagem = res.data.total;
          } else {
            this.currentPage = this.pagina_atual;
            if (
              (typeof n === "object" || typeof n === "function") &&
              n !== null
            ) {
              const routePg = pagina.routePg;
              const res = await axios.get(n.url+routePg);
              this.item = [];
              this.item = res.data.data;
              this.links = res.data.links;
              this.itemPag = res.data.links;
              this.contagem = res.data.total;
              this.perPage = res.data.per_page;
            } else {
              const res = await Aluno.obtemTodos(this.currentPage);
              this.item = [];
              this.item = res.data.data;
              this.links = res.data.links;
              this.itemPag = res.data.links;
              this.contagem = res.data.total;
              this.perPage = res.data.per_page;
            }
          }
        }
      }
      this.carregandoPagina = false;
    },
    editarAluno(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "aluno-basic-info-editar",
          params: { id: id, pg: this.currentPage },
        });
      } else {
        this.$router.push({
          name: "aluno-basic-info-cadastrar-do-aluno-coordenacao-editar",
          params: { id: id, pg: this.currentPage },
        });
      }
    },
    registar() {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({ name: "aluno-basic-info-cadastrar" });
      } else {
        this.$router.push({
          name: "aluno-basic-info-cadastrar-do-aluno-coordenacao",
        });
      }
    },
    async fotos(data) {
      //console.log(data);
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "fotos",
          params: { id: data.id, pg: this.currentPage },
        });
      } else {
        this.$router.push({
          name: "fotos-coord",
          params: { id: data.id, pg: this.currentPage },
        });
      }
    },
    async adicionarAlunoTurma(id) {
      try {
        if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
          this.$router.push({
            name: "alunoaddturma",
            params: { id: id, pg: this.currentPage },
          });
        } else {
          this.$router.push({
            name: "alunoaddturma",
            params: { id: id, pg: this.currentPage },
          });
        }
      } catch (e) {}
    },
    async historicoAluno(id) {
      try {
        if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
          this.$router.push({
            name: "alunohistorico",
            params: { id: id, pg: this.currentPage },
          });
        } else {
          this.$router.push({
            name: "coordenacao-alunos-hist",
            params: { id: id, pg: this.currentPage },
          });
        }
      } catch (e) {
        //  console.log(e);
      }
    },
    addArquivo(id) {
      if (this.funcao == 1 || this.funcao == 2 || this.funcao == 7) {
        this.$router.push({
          name: "alunoArquivo",
          params: { id: id, pg: this.currentPage },
        });
      } else {
        this.$router.push({
          name: "coordenacao-alunos-arq",
          params: { id: id, pg: this.currentPage },
        });
      }
    },
    async deletarAluno(id) {
      try {
        if (window.confirm("Você realmente deseja apagar o aluno?")) {
          const data = await Aluno.deletarAluno(id);
          if (data.data.erro) {
            this.listarTudo();
            this.$vaToast.init({
              message: data.data.erro,
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "danger",
            });
          } else {
            this.$vaToast.init({
              message: "Excluído com sucesso",
              iconClass: "fa-star-o",
              position: "top-right",
              duration: 3500,
              fullWidth: false,
              color: "success",
            });
          }
        }
      } catch (error) {
        if (error.response.data.validacao) {
          Object.entries(error.response.data.validacao).forEach(
            ([key, value]) => {
              setTimeout(() => {
                this.$vaToast.init({
                  message: value[0],
                  iconClass: "fa-star-o",
                  position: "top-right",
                  duration: 3500,
                  fullWidth: false,
                  color: "danger",
                });
              }, 500);
            }
          );
        } else {
          this.$vaToast.init({
            message: "Ocorreu um problema",
            iconClass: "fa-star-o",
            position: "top-right",
            duration: 3500,
            fullWidth: false,
            color: "danger",
          });
        }
      }
    },
    async listarTudo() {
      try {
        this.currentPage = this.pg;
        this.item = [];
        this.carregandoPagina = true;
        const data = await Aluno.obtemTodos(this.currentPage);
        this.carregandoPagina = false;
        this.item = data.data.data;
        this.links = data.data.links;
        this.itemPag = data.data.links;
        this.contagem = data.data.total;
        //this.item.reverse();
      } catch (e) {}
    },
    async verificarEmail() {
      if (this.info.email == "" || this.info.email == null) {
        this.emailInvalidoJaExiste = false;
        this.greenColor = "";
        return false;
      }

      const data = await Verificar.verificarJaExisteEmail(this.info.email);
      try {
        if (this.info.id == undefined) {
          if (data.data) {
            this.emailInvalidoJaExiste = true;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.greenColor = "border-color: #42d36f;";
            this.emailInvalidoJaExiste = false;
            return true;
          }
        } else if (
          this.info.id != undefined &&
          this.info.email !== this.info.emailDele
        ) {
          if (data.data) {
            this.emailInvalidoJaExiste = true;
            this.greenColor = "border-color: #f44336;";
            return false;
          } else {
            this.greenColor = "border-color: #42d36f;";
            this.emailInvalidoJaExiste = false;
            return true;
          }
        } else {
          this.emailInvalidoJaExiste = false;
          this.greenColor = "";
        }
      } catch (e) {
        this.emailInvalidoJaExiste = false;
        this.greenColor = "";
      }
    },
    validateForm() {
      const ok = this.info.senha === this.info.confirmarSenha;

      if (!ok) {this.validationErrors["senha"] = true;}
      else {delete this.validationErrors["senha"];}

      if (!ok) {this.validationErrors["confirmarSenha"] = true;}
      else {delete this.validationErrors["confirmarSenha"];}

      if (
        this.info.conectividade == "1" &&
        (this.info.tipos_dispotivos == "" ||
          this.info.tipos_dispotivos == undefined)
      )
        {this.validationErrors["tipos_dispotivos"] = true;}
      else {delete this.validationErrors["tipos_dispotivos"];}
      return !Object.keys(this.validationErrors).length;
    },
  },
});
</script>

<style lang="scss" scoped>
.badge-secondary2 {
  color: #fff;
  background-color: #6c757d;
  font-size: 9px;
}

.table-example--pagination {
  padding-top: 20px;
  text-align: center;
  text-align: -webkit-center;
}
</style>

<style>
.pm-card {
  background: #fff;
  padding: 2rem;
  border-radius: 10px;
  margin-bottom: 2rem;
}
</style>

<style scoped>
  .turma-dados-disabled {
    pointer-events: none;
    opacity: 0.5; /* Adjust the opacity to your preference */

  }
</style>
